<template>
  <div>
    <div class="container">
      <div class="my-form mb-3 position-relative">
        <label for="foodInput" class="form-label title-label">Geben Sie ein Lebensmittel ein:</label>
        <textarea class="form-control" id="foodInput" rows="3" v-model="foodInput" @input="updateCharCount" @keydown.enter.prevent="submitForm"></textarea>
        <div class="char-counter position-absolute bottom-0 end-0 text-muted counter-style">{{ charCount }}/30</div>
      </div>
    </div>
    <div class="my-block">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="confirmCheckbox" v-model="confirmChecked">
        <label class="form-check-label checkboxtext-style" for="confirmCheckbox">
          Mit der Nutzung des Nährstoffrechners von <a href="https://schoenkost.com" target="_blank">Schönkost</a> stimme ich zu, dass meine Anfrage von externen Diensten bearbeitet wird. Mehr dazu in der <a href="https://schoenkost.com/datenschutzerklaerung/" target="_blank">Datenschutzerklärung</a>.
        </label>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <button class="btn btn-primary my-button" type="button" @click="submitForm" :disabled="!confirmChecked || !foodInput.trim()">
          Nährwerte abfragen
        </button>
      </div>
    </div>

    <div class="row mt-3 justify-content-center">
      <div class="col-md-6">
        <div class="card my-card">
          <div class="card-body align-items-center">
            <div v-if="nutritionData || error" class="nutrition-data">
              <h2 class="nutrition-title">Nährwerte pro 100g:</h2>
              <div v-if="nutritionData" class="nutrition-items">
                <p v-if="userInput" class="nutrition-item eingabe-text"><strong>{{ capitalizeFirstLetter(userInput) }}</strong></p>
                <p class="nutrition-item"><strong>Kalorien:</strong> {{ nutritionData.calories }}</p>
                <p class="nutrition-item"><strong>Eiweiß:</strong> {{ nutritionData.protein }} g</p>
                <p class="nutrition-item"><strong>Fett:</strong> {{ nutritionData.fat }} g</p>
                <div class="nutrition-item no-border-bottom">
                  <strong>Kohlenhydrate:</strong> {{ nutritionData.carbs }} g
                  <br>
                  <span style="text-indent:1em; font-style:italic;">dav. Ballaststoffe: {{ nutritionData.fiber }} g</span>
                </div>
              </div>
              <div v-else-if="error" class="error-message">
                <p :class="errorClass">{{ error }}</p>
              </div>
              <p class="source-text">Quelle: EDAMAM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      foodInput: '',
      charCount: 0,
      nutritionData: null,
      error: null,
      userInput: '',
      confirmChecked: false,
    };
  },
  methods: {
    updateCharCount() {
      if (this.foodInput.length <= 30) {
        this.charCount = this.foodInput.length;
      } else {
        this.foodInput = this.foodInput.slice(0, 30);
        this.charCount = 30;
      }
    },
    submitForm() {
      if (!this.confirmChecked) {
        return;
      }

      const translationEndpoint = '/api/translations'; // Anpassen, wenn das Backend auf einem anderen Server oder Port läuft

      axios
        .get(translationEndpoint, {
          params: {
            text: this.foodInput,
          },
        })
        .then((response) => {
          const translatedText = response.data.translatedText;
          this.userInput = this.foodInput;
          this.foodInput = '';
          this.charCount = 0;
          this.fetchNutritionData(translatedText);
        })
        .catch((error) => {
          console.error('Fehler bei der Übersetzung:', error);
        });
    },
    fetchNutritionData(text) {
      this.nutritionData = null; // Setze nutritionData auf null vor der Anfrage
      const nutrientsEndpoint = '/api/nutrients';

      axios
        .get(nutrientsEndpoint, {
          params: {
            ingr: text,
          },
        })
        .then((response) => {
          if (response.status === 404) {
            this.error = 'Zu dieser Anfrage wurden keine Nährwerte gefunden';
            this.nutritionData = null;
          } else {
            this.error = null;
            this.nutritionData = response.data;
          }
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Nährwerte:', error);
          if (error.response && error.response.status === 404) {
            this.error = 'Zu dieser Anfrage wurden keine Nährwerte gefunden';
            this.nutritionData = null;
          } else {
            this.error = 'Fehler beim Abrufen der Nährwerte';
            this.nutritionData = null;
            this.foodInput = '';
            this.charCount = 0;
          }
        });
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  computed: {
    errorClass() {
      return {
        'error-text': this.error,
      };
    },
  },
};
</script>

<style scoped>

body {
  overflow-x: hidden;
}

/* Allgemeine Schriftarten */
.checkboxtext-style,
.counter-style,
.title-label,
.my-form textarea,
.my-form textarea::placeholder,
.my-form textarea:focus,
.my-button,
.my-form .char-counter,
.nutrition-data,
.nutrition-title,
.nutrition-items,
.nutrition-item,
.error-text,
.error-message,
.source-text {
  font-family: 'Poppins', Arial, sans-serif;
}

/* Überschrift-Styling */
.title-label {
  font-weight: bold;
  font-size: 22px;
  margin-top: 5%;
  margin-bottom: 2%;
}

/* CheckboxtextStyling */
.checkboxtext-style {
  text-align: left;
}

/* Checkbox-Block */
.my-block {
  width: 90%;
  height: auto;
  max-width: auto;
  margin: 15px auto;
}

.my-block .form-check-input[type="checkbox"]:not(:checked),
.my-block .form-check-input[type="checkbox"]:not(:checked):focus {
  background-color: #E59A59 !important;
  border-color: #E59A59 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.my-block .form-check-input:checked,
.my-block .form-check-input:checked:focus {
  background-color: #E59A59 !important;
  border-color: #E59A59 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.my-block .form-check-input:checked::before {
  content: '' !important;
  background-color: #E59A59 !important;
  border-color: #E59A59 !important;
}

/* Button-Styling */
.my-button[disabled],
.my-button:hover,
.my-button:focus {
  outline: none;
  box-shadow: none !important;
}

.my-button {
  color: #FFFFFF !important;
  background-color: #E59A59 !important;
  border-color: transparent;
  transition: background-color 0.3s ease;
  font-weight: bold;
  width: 100%;
  max-width: 220px;
  display: flex;
  justify-content: center;
}

.my-button:hover,
.my-button:focus {
  background-color: #D46941 !important;
  border-color: transparent !important;
}

.my-button-container {
  display: flex;
  justify-content: center;
}

/* Karten-Styling */
.my-card {
  width: 90%;
  height: auto;
  min-height: 520px;
  max-width: auto;
  margin: 15px auto;
  box-shadow: 0 0 1px 1px #31525B;
}

/* Textarea-Styling */
.my-form textarea,
.my-form textarea::placeholder,
.my-form textarea:focus {
  width: 100%;
}

.my-form textarea::placeholder {
  color: #31525B;
}

.my-form textarea:focus::placeholder {
  color: transparent;
}

.my-form textarea {
  border-color: #c4c4c2;
  box-shadow: 0 0 5px 2px rgba(196, 196, 194, 0.5);
}

.my-form textarea:focus {
  border-color: #E59A59 !important;
  box-shadow: 0 0 5px 0px #E59A59 !important;
}

.my-form .char-counter {
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-size: 12px;
  color: #31525B;
}

.eingabe-text strong {
  font-size: 20px;
  color: #D46941;
}

/* Nährwert-Daten-Styling */
.nutrition-data {
  background-color: #f6f4e8;
  padding: 20px;
  border-radius: 5px;
  text-align: left;
}

.nutrition-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}

.nutrition-items {
  display: flex;
  flex-direction: column;
}

.nutrition-item {
  font-size: 18px;
  margin-bottom: 5px;
  text-align: left;
  padding-bottom: 5px;
}

.nutrition-data p:last-child {
  border-bottom: none;
}

/* Graue Linie unter "Nährwerte pro 100g:" */
.nutrition-title::after {
  content: "";
  display: block;
  width: 80%;
  height: 6px;
  background-color: #31525B;
  margin: 10px auto;
}

/* Graue Linie unter Nutzerabfrage */
.nutrition-items .eingabe-text::after {
  content: "";
  display: block;
  width: 40%;
  height: 4px;
  background-color: #D46941;
  margin: 0 auto 10px 0;
}

.nutrition-item.no-border-bottom {
  border-bottom: none;
}

.nutrition-item:not(.eingabe-text) {
  border-bottom: 2px solid #D46941;
  width: 80%;
}

/* Korrektur: Keine Trennlinie zwischen Kohlenhydrate und Ballaststoffe */
.nutrition-item.no-border-bottom + p.nutrition-item:not(.eingabe-text) {
  border-top: none !important;
}

.source-text {
  position: absolute;
  bottom: 0%;
  right: 20px;
  font-style: italic;
  font-size: 10px;
  color: #888;
  font-family: 'Poppins', Arial, sans-serif;
  white-space: nowrap;
 
}

.container {
  overflow-x: hidden;
}

.error-text {
  color: red;
  font-weight: bold;
  text-align: center;
}


/* Media Query für kleine Bildschirme */
@media (max-width: 290px) {
  .source-text {
    position: static;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
