<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png" class="logo">
    <VuetriCalc/>
  </div>
</template>

<script>
import VuetriCalc from './components/VuetriCalc.vue'

export default {
  name: 'App',
  components: {
    VuetriCalc
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
  overflow-x: hidden; /* Verhindert das horizontale Scrollen */
}

.logo {
  width: 60px; /* Hier die Breite anpassen */
  height: auto; /* Die Höhe wird automatisch angepasst, um das Seitenverhältnis beizubehalten */
}
</style>